import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['accountSlug']),
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments', ['deleteFinalizedDocument']),
    getFinalizedDocumentWithStatus (finalizedDocument) {
      let statusIcon = null
      let statusText = null
      let statusClass = null
      let statusArrowIcon = false
      let statusTooltip = null

      if (!finalizedDocument.isPurchased) {
        statusIcon = 'alert-circle'
        statusText = 'Plačaj'
        statusArrowIcon = true
        statusClass = 'status--pay'
        statusTooltip = 'Neplačani dokumenti se po 30 dneh samodejno izbrišejo.'
      } else if (finalizedDocument.status === 'warning') {
        statusText = 'Poteče kmalu'
        statusClass = 'status--warning'
      } else if (finalizedDocument.status === 'expired') {
        statusText = 'Neveljavno'
        statusClass = 'status--expired'
      } else if (finalizedDocument.isESigned || finalizedDocument.signedOn) {
        statusIcon = 'checkmark-circle-green'
        statusText = 'Veljavno'
        statusClass = 'status--signed'
      } else if (finalizedDocument.isWaitingESignature) {
        statusText = `V teku (${finalizedDocument.signingProgress})`
        statusClass = 'status--signing'
      } else if (finalizedDocument.isPurchased) {
        statusText = 'Podpiši'
        statusClass = 'status--signable'
        statusArrowIcon = true
      }

      return {
        ...finalizedDocument,
        statusIcon,
        statusText,
        statusClass,
        statusArrowIcon,
        statusTooltip,
      }
    },
    openEditFinalizedDocument (finalizedDocument, isMiddleClick = false) {
      const routeData = {
        name: 'account_finalized_document_edit_path',
        params: {
          id: finalizedDocument.id,
          accountId: this.accountSlug,
        },
      }
      if (isMiddleClick) {
        window.open(this.$router.resolve(routeData).href, '_blank')
      } else {
        this.$router.push(routeData)
      }
    },
    openPreviewFinalizedDocument (finalizedDocument, openInNewTab = false) {
      if (openInNewTab) {
        window.open(finalizedDocument.previewUrl, '_blank')
      } else {
        window.open(finalizedDocument.previewUrl)
      }
    },
    openFinalizedDocumentPayment (finalizedDocument) {
      this.$router.push({
        name: 'finalized_document_payment',
        params: {
          id: finalizedDocument.id,
        },
      })
    },
    openFinalizedDocumentSignature (finalizedDocument) {
      this.$router.push({
        name: 'account_finalized_document_e_signature_path',
        params: {
          id: finalizedDocument.id,
          accountId: this.accountSlug,
        },
      })
    },
    formatColumnValue (value, isDate) {
      if (!isDate) {
        return value
      }

      const dateValue = new Date(value)
      return isNaN(dateValue) ? value : dateValue.toLocaleDateString('sl')
    },
    onNameClick (finalizedDocument) {
      if (finalizedDocument.isPurchased) {
        window.open(finalizedDocument.previewUrl, '_blank')
      } else {
        this.openFinalizedDocumentPayment(finalizedDocument)
      }
    },
    onStatusClick (finalizedDocument) {
      if (!finalizedDocument.isPurchased) {
        this.openFinalizedDocumentPayment(finalizedDocument)
      } else if (!finalizedDocument.signedOn) {
        this.openFinalizedDocumentSignature(finalizedDocument)
      }
    },
  },
}
